<template>
  <div class="test">
    <slot />
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="postcss">
.test {
  min-height: 200px;
}
</style>
